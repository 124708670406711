<template>
  <div class="listing-details" v-if="detail"   >
    <Head :type="0" share="true" title="车辆详情" v-model="showShare"/> 
     
   
     <van-share-sheet v-model="showShare" cancel-text="" title="立即分享给好友"  :options="options"  @select="onSelect"/>
    

    <div class="main" v-if="detail" @touchmove.stop>
      <div class="swiper">
        <van-swipe @change="onChange">
          <van-swipe-item v-for=" item in detail.imgUrls" :key="item">
            <img :src="item" alt="车辆图片">
          </van-swipe-item>
          <template #indicator>
            <div class="custom-indicator">{{ current + 1 }}/{{imgSize}}</div>
          </template>
        </van-swipe>
      </div>
     
      <div class="car-pirce">
          <div class="prices">
          <div class="now-price">{{detail.listedPrice}}万</div>
          <p>{{detail.sellingPoints}}</p>
          </div>
          <div class="contact">
            <a :href="'tel:'+detail.salesPhone">
            <img class="" src="../../assets/img/phone.png" alt="">
            <span>联系销售</span>
            </a>
            </div>
      </div>
      <div class="car-desc"> {{detail.name}}</div>
      <div class="car-box" v-if="detail!==null">
        <div class="info-item" >
          <div class="title">上牌时间</div>
          <div class="list" v-if="detail.licensingTime">{{detail.licensingTime.split(' ')[0].replace(/-/,'.').slice(0,7)}}</div>
          <div class="center" v-if="!detail.licensingTime">- -</div>

        </div>
        <div class="info-item"  >
          <div class="title">表显里程</div>
          <div class="list" v-if="detail.mileage!==null">{{detail.mileage}}万公里</div>
          <div class="center" v-if="!detail.mileage">- -</div>
          
        </div>
        <div class="info-item"  >
          <div class="title"  >排量/变速</div>
          <div class="list" v-if="detail.variableSpeed !==null">{{detail.displacement}}L/ {{detail.variableSpeed=='0'?"手动":''+
                              detail.variableSpeed==='1'?"自动":''+
                              detail.variableSpeed==='2'?"手自一体":''+
                              detail.variableSpeed==='3'?"无极":''
                            }}</div>
          <div class="center" v-if="!detail.variableSpeed">- -</div>

        </div>
        <div class="info-item" >
          <div class="title">排放标准</div>
          <div class="list"  v-if="detail.emissionStandard!==null">{{detail.emissionStandard}}</div>
          <div class="center" v-if="!detail.emissionStandard">- -</div>

        </div>
        <div class="info-item" >
          <div class="title">牌照归属</div>
          <div class="list" v-if="detail.ownershipOfLicensePate!==null">{{detail.ownershipOfLicensePate}}</div>
          <div class="center" v-if="!detail.ownershipOfLicensePate">- -</div>

        </div>
        <div class="info-item"  >
          <div class="title">过户次数</div>
          <div class="list" v-if="detail.transferTimes!==null">过户{{detail.transferTimes}}次</div>
          <div class="center" v-if="detail.transferTimes===null">- -</div>

        </div>
         <div class="info-item"  >
          <div class="title">有无抵押</div>
          <div class="list" v-if="detail.mortgage!==null">{{detail.mortgage==='0'?"无抵押":'有抵押'}}</div>
          <div class="center" v-if="!detail.mortgage">- -</div>

        </div>
        <div class="info-item" >
          <div class="title" >车身颜色</div>
          <div class="list"  v-if="detail.color!==null">{{detail.color}}</div>
          <div class="center" v-if="!detail.color">- -</div>
        </div>
        <div class="info-item" >
          <div class="title" >所在城市</div>
          <!-- .split('/')[detail.city.split('/').length-1] -->
          <div class="list"  v-if="detail.city!==null">{{detail.city}}</div>
          <div class="center" v-if="!detail.city">- -</div>
        </div>
        <div class="info-item" >
          <div class="title">车辆性质</div>
          <div class="list"  v-if="detail.vehicleProperties!==null">{{detail.vehicleProperties==='0'?'非运营':'运营'}}</div>
          <div class="center" v-if="!detail.vehicleProperties">- -</div>

        </div>
        <!-- <iframe src="YD20210601://" frameborder="0">1111</iframe> -->
        <div class="info-item" >
          <div class="title">车辆类型</div>
          <div class="list"  v-if="detail.typeName!==null">{{detail.typeName}}</div>
          <div class="center" v-if="!detail.typeName">- -</div>

        </div>
      </div>
      <!-- <div @click="$router.push('/')">home</div> -->
      <div class="advantage">
        <div class="title" v-if="detail.advantage">本车优势</div>
        <div class=".content">
            <div class="desc">{{detail.advantage}}</div>
            <div class="imgs" v-if="detail.imgUrls">
                <img class="img1" referrerpolicy="no-referrer" :src="detail.imgUrls[0]"/>
                <ul>
                    <li v-for="item,index in detail.imgUrls" :key="item" v-show="index">
                        <img class="pic1" referrerpolicy="no-referrer" :src="item" />
                    </li>
                </ul>

            </div>
        </div>
      </div>
    </div>

    <div class="footer" v-if="detail" @touchmove.prevent>
      <div class="box">
      <div class="collection">
      <img @click="shoucang()"  v-if="detail.isCollect=='0'" src="../../assets/img/Star.png" alt="">
      <img  v-if="detail.isCollect!='0'" src="../../assets/img/readstar.png" @click="cCT(detail.isCollect).then(()=>{detail.isCollect='0'})" alt="">

      <div>收藏</div>
      </div>
      
      <div class="btns" v-if="detail">
        <div class="talk" @click="$contact(detail.imAccount)"> 私聊销售</div>
        <div class="get-phone"><a :href="'tel:'+detail.salesPhone">立即电联</a></div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import {getVehicleDetail} from '../../api/vehicleDetails/index'
import Head from '../../components/head/index.vue'
export default {
  data() {
    return {
      current: 0,
        showShare: false,
        detail:null,//车辆详情数据
        imgSize:0,
        shareClass:{
              picUrl:null,
              platformId:null,
              title:null,
              openUrl:null,
              content:null,
        },
          id:  this.$route.query.id  || "1434078230766333954",
      options: [
        [
          { name: '微信', icon: 'wechat',index:0 },
          { name: '朋友圈', icon: 'wechat-moments', index:1 },
          { name: 'QQ', icon: 'qq' ,index:2},
        ],
       
      ],
    };
    
    
  },
  components:{Head},
  created(){


       
        if(navigator.userAgent.indexOf('万民')==-1){
          this.openApp()
            return
        }
   
try{ 
    this.$delete(this.$route.query, 'aaa');

}catch{ 
  //
}
    
  
    // this.$store.commit("setSession", this.$route.query.session||'a6c57abd-6f8c-497f-b91b-e6e2b64fdb84' )
   
    getVehicleDetail(this.id).then(res=>{
      console.log(res);
      if(res.data.code===0){
        this.detail=res.data.data
        this.imgSize=this.detail.imgUrls.length
      }
    })
  },
  methods: {
    onChange(index) {
      this.current = index;
    },
   
  //    openShare(){
  //   this.showShare=true
  // },

  // hideAerlt(e){ 
  //    e = e || window.event
  //  if(e.preventDefault){ 
  //    e.preventDefault()
  //    return
  //  }
  //  e.returnValue=false
  // },
  onSelect(index){
    this.shareInfo({
      title:this.detail.name,
      picUrl:this.detail.imgUrls[0]|| "https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnge075b8c61cca1d61d6fd515ef1b8fe29671548b08ff1d214b42b6e4dded6c95e",
      platformId:index.index,
      openUrl:'',
      content:this.detail.sellingPoints
      // this.$route.fullPath+"?id="+this.$route.query.id
    })
  },
    shoucang(){
      this.collection({
        type:'3',
        relationId:this.detail.id
      }).then(res=>{
        if(res.data.code===0){
          this.$toast.success('收藏成功')
           getVehicleDetail(this.id).then(res=>{
          console.log(res);
          if(res.data.code===0){
         this.detail=res.data.data
          this.imgSize=this.detail.imgUrls.length
      }
    })
        }else{
          this.$toast.fail('收藏失败')
        }
      })
    },
   filter(data){
     debugger
     let timer =data.split(/-/)[1]+'月' +data.split(/-/)[2].split(' ')[0]+'日'
     data.split(/-/)
      
     return timer+' '+new Date(data).getDate()
   }
  },
  mounted() {
    this.setRem();
    window.addEventListener("orientationchange", this.setRem);
    window.addEventListener("resize", this.setRem);

  },
  
 
};
</script>

<style lang="less" scoped>
  .share {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: .266667rem;
    box-sizing: border-box;
    img{ 

      width: 0.64rem;
      height: 0.64rem
    };
     
    }
.listing-details::-webkit-scrollbar{
  display: none;
}
.listing-details {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  font-family: PingFangSC-Medium, PingFang SC;
  display: flex;
  flex-direction: column;
  // .head {
  //   padding: .266667rem;
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   background-color: white;
  //   border-bottom: 0.013333rem solid #ccc;
  //   box-sizing: border-box;
  //   .title {
  //     margin: auto;
  //     font-size: 0.453333rem;
  //     font-family: PingFangSC-Semibold, PingFang SC;
  //     font-weight: 600;
  //     color: #000000;
  //     padding-left: 0.16rem;
  //   }
  //   .back {
  //     width: 0.666667rem;
  //     height: 0.64rem;
     
  //   }
  //   .share {
  //     width: 0.64rem;
  //     height: 0.64rem;
  //     margin-right: 0.07rem;
  //   }
  // }
  .main::-webkit-scrollbar{
    display: none;
  }
  .main {
    background-color: white;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    .swiper {
      width: 100%;
      height: 4.8rem;
      padding-bottom: 0.266667rem;
      img{
        width: 100%;
        height: 100%;
      }
      .custom-indicator {
        position: absolute;
        right: 0.066667rem;
        bottom: 0.066667rem;
        padding: 0.026667rem 0.066667rem;
        font-size: 0.16rem;
        background: rgba(0, 0, 0, 0.1);
      }
      .van-swipe {
      
        height: 100%;
        
      }
    }
    .car-pirce{
        padding:.533333rem  .426667rem 1.066667rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .prices{
            .now-price{
            color: #A80000FF;
            font-size: .533333rem;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
        }
            p{
                color: #999;
                font-size: .32rem;
                font-family: PingFangSC-Regular, PingFang SC;
                line-height: 34px;
            }
        }
        .contact{
           
            a{
               display: flex;
            flex-direction: column;
            align-items: center;
           
            img{
                width: .64rem;
                height: .64rem;
                display: block;
               
                margin-bottom: .133333rem;
            }
            span{
                display: inline-block;
                font-size: .32rem;
                transform: scale(0.83);
                color: #999;
            }
             }
        }
    }
    .car-desc {
      padding: 0 .426667rem;
      height: 1.493333rem;
      font-size: .453333rem;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 0.746667rem;
      
    }
    .car-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0.266667rem .426667rem 1rem;
      font-size: .32rem;
      font-weight: 500;
    }
    .info-item:nth-of-type(3n){
    padding-left: 8%;
    
    }
    .info-item:nth-of-type(3n+2){
    padding-left: 5%;

    }
    .info-item {
      //  text-align: justify;
      box-sizing: border-box;
       width: 33.3%;
      display: flex;
      flex-direction: column;
      margin-bottom: .80rem;
      justify-content: space-between;
      .title {
        
        font-family: PingFangSC-Semibold, PingFang SC;
        color: #bbb;
       
      }
      .list {
        height: 0.453333rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 0.453333rem;
      }
      .center{
        margin-left: .32rem;
      }
    }
    .car-info {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      font-size: 0.32rem;
      justify-content: flex-start;
     margin-bottom: .453333rem;
      box-sizing: border-box;
      li {
        display: block;
        min-width: 50%;
        text-align: left;
        margin-bottom: 0.266667rem;
        .key {
          color: #ccc;
          margin-right: 0.133333rem;
        }
        .value {
          color: black;
        }
      }
    }
    .advantage{
      padding: 0 .426667rem;
      font-size: .373333rem;
      color: #aaa;
      .title{
        font-size: .453333rem;
        color: black;
        font-weight: 600;
        margin-bottom: .266667rem;
        margin-bottom: .24rem;
      }
     

      
      img{
          width: 100%;
          margin-bottom: .266667rem;
          height: 4.8rem;
      }
      ul{
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        li{     
            display: block;
              width: 4.4rem;
             height: 2.4rem;
             margin-bottom: .266667rem;
             img{
                 width: 4.4rem;
                height: 2.4rem;
             }
        }
      }
      
    }
  }
  .footer{
    padding: 0 .426667rem 0 .666667rem ;

    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    position: relative;
    
    border-top: 1px solid #eee;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    background-color: white;
    .box{
      display: flex;
    align-items: center;
    justify-content: space-between;
    padding:0.26rem 0;
    }
    .collection{
      display: flex;
      flex-direction: column;
      margin-right: .666667rem;
      img{
        width: .666667rem;
        height: .64rem;
      }
      div{
        font-size: .32rem;
        transform: scale(0.83);
      }
    }
    .btns{
      display: flex;
    
      color: white;
      font-size: .373333rem;
      text-align: center;
      align-items: center;
      height: .96rem;
      .talk{
        width: 3.786667rem;
        background-image: url('../../assets/img/btn1_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: .96rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .get-phone{
         width: 3.786667rem;
        background-image: url('../../assets/img/btn2_bg.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: .96rem;
         display: flex;
        align-items: center;
        justify-content: center;
        a{
          color: white;
          background-image: linear-gradient();
        }
      }
    }

  }
}
</style>